/* For XXL Screen (768p(1366 x 768) above - 1080p , 1920 x 1080) */
@media (min-width: 1399.99px) {}

/* For XL Screen (Laptop - HD Ready Display) */
@media only screen and (min-width: 1199.9px) and (max-width: 1399.98px) {
    .section-padding {
        padding: 80px 0;
    }

    .breadcrumb-title {
        font-size: 30px
    }

    .breadcrumb-item {
        font-size: 15px;
    }

    .breadcrumb-section {
        padding: 160px 0 80px;
    }

    .section-heading {
        font-size: 30px;
    }

    .content-margin-top {
        margin-top: 60px !important;
    }

    .custom-card-tile {
        font-size: 25px;
    }

    .section-main-image-area .section-main-image {
        max-width: 80%;
    }

    .detail-card-info-body h6 {
        font-size: 15px;
    }
}

/* For LG (Large) Screen  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding {
        padding: 80px 0;
    }

    .breadcrumb-section {
        padding: 160px 0 80px;
    }

    .breadcrumb-title {
        font-size: 30px
    }

    .breadcrumb-item {
        font-size: 15px;
    }

    .section-heading {
        font-size: 30px;
    }

    .content-margin-top {
        margin-top: 60px !important;
    }

    .custom-card {
        padding: 25px;
    }

    .custom-card-tile {
        font-size: 23px;
    }

    .custom-card-sub-tile {
        font-size: 15px;
    }

    .section-main-image-area .section-main-image {
        max-width: 85%;
    }

    .custom-card-logo-area .custom-card-logo.center-panel-logo {
        max-width: 22%;
    }

    .detail-card-label-area.main h5 {
        font-size: 16px !important;
    }

    .detail-card-info-body h6 {
        font-size: 15px !important;
    }

    .detail-card-info-icon-area i {
        font-size: 18px;
    }

    .account-agree-area label,
    .account-agree-area label a {
        font-size: 13px;
    }

    /*  */
    .footer-logo-link {
        margin-left: auto;
        margin-right: auto;
    }
}


/* For MD (Medium) Screen (ipad) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding {
        padding: 80px 0;
    }

    .breadcrumb-section {
        padding: 120px 0 80px;
    }

    .breadcrumb-title {
        font-size: 30px
    }

    .breadcrumb-item {
        font-size: 15px;
    }

    .footer-link-area.contact-detail {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        border: 1px solid #5a57b9;
        border-radius: 20px;
        margin-top: 20px;
        padding: 30px;
        margin-bottom: 20px;
        box-shadow: 0px 0px 20px #b0aef718;
        background-color: #2f2d7e;
    }

    .footer-link-area.contact-detail .footer-link-item {
        margin-bottom: 0;
    }

    .mobile-menu-section {
        width: 50% !important;
        margin-left: auto;
        transform: translateX(100%) !important;
    }

    .mobile-menu-section.show {
        transform: translateX(0) !important;
    }

    .menu-backdrop {
        display: block;
    }

    .section-heading {
        font-size: 30px;
    }

    .content-margin-top {
        margin-top: 60px !important;
    }

    .section-image-area .about-img {
        max-width: 40%;
    }

    .custom-card-tile {
        font-size: 25px;
    }

    .custom-card-sub-tile {
        font-size: 15px;
    }

    .section-main-image-area .section-main-image {
        max-width: 60%;
    }

    .content-section h4 {
        font-size: 20px;
    }

    .content-collapse-section {
        margin-top: 20px;
    }

    .custom-card-logo-area .custom-card-logo.center-panel-logo {
        max-width: 35%;
    }
}

/* For SM (Small) Screen (Tab) */
@media (min-width: 576px) and (max-width: 767px) {
    .section-padding {
        padding: 80px 0;
    }

    .breadcrumb-section {
        padding: 120px 0 80px;
    }

    .breadcrumb-title {
        font-size: 30px
    }

    .footer-link-area.contact-detail {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        border: 1px solid #5a57b9;
        border-radius: 20px;
        margin-top: 20px;
        justify-content: center;
        padding: 30px;
        margin-bottom: 20px;
        box-shadow: 0px 0px 20px #b0aef718;
        background-color: #2f2d7e;
    }

    .footer-link-area.contact-detail .footer-link-item {
        margin-bottom: 0;
    }

    .nav-container {
        padding: 0 20px;
    }

    .mobile-menu-section {
        width: 60% !important;
        margin-left: auto;
        transform: translateX(100%) !important;
    }

    .mobile-menu-section.show {
        transform: translateX(0) !important;
    }

    .menu-backdrop {
        display: block;
    }

    .section-heading {
        font-size: 30px;
    }

    .content-margin-top {
        margin-top: 60px !important;
    }

    .section-image-area .about-img {
        max-width: 40%;
    }

    .custom-card-tile {
        font-size: 25px;
    }

    .custom-card-sub-tile {
        font-size: 15px;
    }

    .section-main-image-area .section-main-image {
        max-width: 60%;
    }

    .content-section h4 {
        font-size: 20px;
    }

    .content-collapse-section {
        margin-top: 20px;
    }

    .custom-card-logo-area .custom-card-logo.center-panel-logo {
        max-width: 35%;
    }
}

/* For Mobile Screen */
@media (max-width: 575px) {
    .menu-btn-link {
        padding: 12px;
    }

    .section-padding {
        padding: 60px 0;
    }

    .footer-desc {
        font-size: 15px;
    }

    .footer-link-head {
        font-size: 16px;
    }

    .footer-link {
        font-size: 14px;
    }

    .subscribe-input-area .subscribe-input {
        padding: 10px 20px;
        text-align: center;
    }

    .subscribe-input-area .subscribe-btn {
        position: relative;
        transform: none;
        display: block;
        margin: 10px auto;
        width: 50%;
    }

    .footer-sub-section {
        padding: 40px 0px;
    }

    .footer-link-area.contact-detail {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        border: 1px solid #5a57b9;
        border-radius: 20px;
        margin-top: 20px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0px 0px 20px #b0aef718;
        background-color: #2f2d7e;
        flex-direction: column;
    }

    .footer-link-area.contact-detail .footer-link-item {
        margin-bottom: 0;
    }

    .footer-social-link {
        width: 42px;
        height: 42px;
    }

    .container-padding {
        padding: 10px;
    }

    .subscribe-input-area .subscribe-input,
    .subscribe-input-area .subscribe-btn {
        font-size: 15px;
    }

    .subscribe-input-area .subscribe-input {
        height: 54px;
    }

    .subscribe-input-area .subscribe-btn {
        height: 44px;
    }

    .nav-container {
        padding: 0 15px;
    }

    .nav-section {
        padding: 25px 0 !important;
    }

    .nav-logo-link img,
    .nav-section.scrolled .nav-logo-link .scrolled-logo {
        height: 50px;
    }

    .nav-section.scrolled {
        padding: 10px 0 !important;
    }

    .breadcrumb-item {
        font-size: 14px !important;
    }

    .back-to-top-btn {
        width: 40px;
        height: 40px;
        right: unset;
        left: 50%;
        transform: translateX(-50%);
    }

    .back-to-top-btn.show {
        bottom: 10px !important;
    }

    .sub-footer {
        padding: 15px 10px;
    }

    .sub-footer p {
        font-size: 12px;
    }

    .breadcrumb-section {
        padding: 120px 0 60px;
    }

    .breadcrumb {
        margin-top: 15px;
    }


    .breadcrumb-title {
        font-size: 25px !important;
    }

    .breadcrumb-item {
        font-size: 14px;
    }

    .section-heading {
        font-size: 25px;
    }

    .content-margin-top {
        margin-top: 50px !important;
    }

    .section-image-area .about-img {
        max-width: 70%;
    }

    .section-paragraph {
        font-size: 15px;
    }

    .content-card-head {
        font-size: 18px;
    }

    .content-card-header {
        margin-bottom: 15px;
    }

    .section-image-area {
        background-color: #ebeafc;
        border-radius: 20px;
        padding: 20px;
    }

    .custom-card-tile {
        font-size: 20px;
    }

    .custom-card-sub-tile {
        font-size: 14px;
    }

    .section-main-image-area .section-main-image {
        max-width: 80%;
    }

    .custom-form-label {
        font-size: 13px;
    }

    .custom-card {
        padding: 20px;
    }

    .custom-card-logo-area .custom-card-logo {
        max-width: 50%;
    }

    .custom-card-logo-area .custom-card-logo.center-panel-logo {
        max-width: 50%;
    }

    .custom-card-logo-area {
        margin-bottom: 20px;
    }

    .custom-check-group .custom-check-label {
        font-size: 13px;
    }

    .custom-check-group .custom-check-label a {
        font-size: 14px;
    }

    .custom-form-link-area p,
    .custom-form-link {
        font-size: 13px;
    }

    .custom-card-form {
        padding: 10px 0;
        background-color: transparent !important;
        border: none;
        margin-top: 20px;
    }

    :root {
        font-size: 15px;
    }

    .content-section h4 {
        font-size: 20px;
    }

    .content-section h5 {
        font-size: 16px;
    }

    .content-section p,
    .content-section ul li,
    .content-section ul li a,
    .content-section p a,
    .content-contact-detail a {
        font-size: 15px;
    }

    .content-info {
        font-size: 14px;
        border-radius: 12px;
    }

    .content-section ul {
        padding-left: 15px;
    }

    .content-card {
        padding: 20px;
    }

    .content-section {
        margin: 20px 0;
    }

    .content-section.has-bg {
        padding: 20px;
        border-radius: 12px;
    }

    .content-collapse-section {
        margin-top: 20px;
    }

    .content-collapse-section .p-accordion .p-accordion-header .p-accordion-header-link {
        font-size: 15px !important;
        padding: 15px;
    }

    .content-collapse-section .p-accordion .p-accordion-content {
        font-size: 14px !important;
    }

    .content-collapse-section .p-accordion-tab {
        margin-bottom: 15px;
    }

    .contact-card-head {
        font-size: 20px !important;
    }

    .contact-form-section {
        padding: 20px 15px !important;
    }

    .custom-card-form.form-2 {
        margin-top: 20px;
    }

    .p-toast.p-component {
        left: 50% !important;
        transform: translateX(-50%) !important;
        width: 90% !important;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
        font-size: 14px;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
        font-size: 12px;
    }

    .back-page-btn {
        font-size: 15px;
    }

    .section-heading::after {
        height: 3px;
    }

    .custom-form-group .p-inputtext,
    .custom-form-group .p-password-input {
        font-size: 14px !important;
    }

    .mobile-menu-section,
    .menu-link-area {
        padding-top: 30px !important;
    }

    .menu-logo-link img {
        height: 70px;
    }

    .btn-width {
        width: 100% !important;
    }

    .form-head-input-area {
        padding: 10px 0px 0px;
        background-color: transparent;
        border-radius: 0px;
    }

    .p-datepicker * {
        font-size: 13px !important;
    }

    .custom-modal .modal-body {
        padding: 10px;
    }

    .custom-modal .modal-header {
        padding: 10px;
    }

    /* .promotion-label h6 {
        font-size: 12px;
        text-wrap: nowrap;
    } */
}


/* /////////// */

@media (max-width: 992px) {
    .menu-toggle-btn-area {
        display: block;
    }

    .nav-link-area,
    .nav-button-grp {
        display: none;
    }

    .container-padding {
        padding: 10px;
    }

    .nav-section {
        padding: 35px 0;
    }

    .hero-section {
        background-size: cover;
        background-position: center;
    }

    .footer-section {
        background-position: unset;
    }

    .mobile-menu-section {
        display: block;
        opacity: 0 !important;
        visibility: hidden !important;
    }

    .mobile-menu-section.show {
        opacity: 1 !important;
        visibility: visible !important;
    }

    .breadcrumb-title {
        font-size: 30px
    }

    .breadcrumb-item {
        font-size: 15px;
    }

    .promotion-label {
        padding: 4px;
    }

    /*  */
    .footer-logo-link {
        margin-left: auto;
        margin-right: auto;
    }
}